import React from 'react';
import "./tabStyle.css"

const TabNavStatusLV = ({title, showStatus, setImg}) => {
    return (
        <div>
            <div className='NavStatusV'></div>
            <div className="mineHeadV">
                <img className="mineHeadImg" src={require('../../assets/tabimg/mineTab/mineProfile.png')}></img>
                <p className='mineTitle'>{title}</p>

                {showStatus === true && <img className="mineHeadImg" src={setImg}></img>}
            </div>

        </div>
       
    );
}

export default TabNavStatusLV;
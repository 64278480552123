import React from 'react';
import "./tabSubStyle.css"

const ListLineV = ({headImg, title, compony,requestStr, priceStr, status}) => {
    return (
        <div className="ListItemV">
            <div className="ItemV">
                <img src={headImg} className="itemheadImg"></img>
                <div className="ItemVTextV">
                    <p className="ItemVTitle">{title}</p>
                    <p className="ItemVCompony">{compony}</p>
                    <p className="ItemVRequest">{requestStr}</p>
                </div>
                <div className="ItemVPrice">
                    <div className='ItemVStatus'>
                        <p className='itemStatusL'>{status}</p>
                    </div>
                    <p className='itemPriL'>{priceStr}</p>
                </div>
            </div>
        </div>
    )
}

export default ListLineV;
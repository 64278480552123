import React from 'react';
import "./tabSubStyle.css"

const MineLineV = ({titleStr, placesStr}) => {
    return (
        <div className="mine-line-v">
            <p className='titleHeadL'>{titleStr}</p>
            
            <div className="mine-line-v__line"></div>
        </div>
    )
}

export default MineLineV;
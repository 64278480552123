import React, { Component } from 'react';
import TabBarView from '../component/TabBarView';
import MainTabView from '../component/maincomponent/MainTabView';
import ListTabView from '../component/maincomponent/ListTabView';
import ChatTabView from '../component/maincomponent/ChatTabView';
import MineTabView from '../component/maincomponent/MineTabView';


class MainContentView extends Component {

  constructor(props) {
    super(props)

    this.state = {
      showControl : 1
    };
  }

 clickTabAction = (index) =>{
    console.log('clickTabBar', index);

    if (this.state.showControl === index) {
      return;  
    }

    this.setState({ showControl: index})
 }

  render() {
    return (
      <div>
        {this.state.showControl === 1 && <MainTabView></MainTabView>}
        {this.state.showControl === 2 && <ListTabView></ListTabView>}
        {this.state.showControl === 3 && <ChatTabView></ChatTabView>}
        {this.state.showControl === 4 && <MineTabView></MineTabView>}
        <TabBarView onClick={this.clickTabAction}></TabBarView>
      </div>
    );
  }
}

export default MainContentView;
import React from 'react';
import './tabStyle.css'

import TabNavStatusLV from './TabNavStatusLV'
import WebApp from '@twa-dev/sdk';

class MainTabView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            userName : WebApp.initDataUnsafe.user.first_name
        };
        console.log("-----------------")
    }

    render() {
        return (
            <div>
                <TabNavStatusLV title="Hello, Edward !" showStatus={true} setImg={require('../../assets/tool/notificationImg.png')}></TabNavStatusLV>
                <div className='mainEmptyBgV'>
                    <img className="emptyImg" src={require('../../assets/tool/emptyImg.png')}></img>
                    <p className='emptyTitle'>Empty</p>
                    <p className='emptyTitle'>{this.state.userName}</p>

                    
                    {/* <p className='emptySubTitle'>Create a job vacancy for your company and start find new high quality employee.</p> */}
                </div>

                

            </div>
        );
    }
}

export default MainTabView;
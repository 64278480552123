import React from 'react';
import './tabStyle.css'
import ChatLineV from './ChatLineV'
import TabNavStatusLV from './TabNavStatusLV'

class ChatTabView extends React.Component {
    constructor(props) {
        super(props)

        console.log("-----------------")
    }

    render() {
        return (
            <div className='tabCommon'  style={{color: 'white'}}>
                <TabNavStatusLV title="Chat Box" showStatus={false}></TabNavStatusLV>

                <ChatLineV headImg={require('../../assets/tabimg/mineTab/mineProfile.png')} userName="Edward" msgStr="Ok, I will come on time, thanks" timeStr="12:32"></ChatLineV>
                <ChatLineV headImg={require('../../assets/tabimg/mineTab/mineProfile.png')} userName="Connie" msgStr="Hello." timeStr="12:32"></ChatLineV>
                <ChatLineV headImg={require('../../assets/tabimg/mineTab/mineProfile.png')} userName="Brooklyn Simmons" msgStr="thanks" timeStr="12:32"></ChatLineV>
                <ChatLineV headImg={require('../../assets/tabimg/mineTab/mineProfile.png')} userName="Jenny Wilson" msgStr="Ok" timeStr="12:32"></ChatLineV>

            </div>
        );
    }
}

export default ChatTabView;
import React, { Component } from 'react';
import './tabStyle.css'
import TabNavStatusLV from './TabNavStatusLV'
import ListLineV from './ListLineV'

class ListTabView extends Component {
    constructor(props) {
        super(props)
        console.log("-----------------")
    }

  render() {
    return (
      <div style={{backgroundColor: 'white'}}>
        <TabNavStatusLV title="Application" showStatus={false}></TabNavStatusLV>
        <ListLineV headImg={require('../../assets/tool/workTestImg.png')} title="AI Designer" compony={"AirBNB"} requestStr={"United States"} priceStr={"$2.350"}></ListLineV>
        <ListLineV headImg={require('../../assets/tool/workTestImg.png')} title="Java Designer" compony={"AirBNB"} requestStr={"United States"} priceStr={"$2.350"}></ListLineV>
        <ListLineV headImg={require('../../assets/tool/workTestImg.png')} title="IOS/Android Designer" compony={"AirBNB"} requestStr={"United States"} priceStr={"$2.350"}></ListLineV>
        <ListLineV headImg={require('../../assets/tool/workTestImg.png')} title="Go Designer" compony={"AirBNB"} requestStr={"United States"} priceStr={"$2.350"}></ListLineV>
        <ListLineV headImg={require('../../assets/tool/workTestImg.png')} title="UI/UX Designer" compony={"AirBNB"} requestStr={"United States"} priceStr={"$2.350"}></ListLineV>
        <ListLineV headImg={require('../../assets/tool/workTestImg.png')} title="UI/UX Designer" compony={"AirBNB"} requestStr={"United States"} priceStr={"$2.350"}></ListLineV>
        <ListLineV headImg={require('../../assets/tool/workTestImg.png')} title="UI/UX Designer" compony={"AirBNB"} requestStr={"United States"} priceStr={"$2.350"}></ListLineV>
        <ListLineV headImg={require('../../assets/tool/workTestImg.png')} title="UI/UX Designer" compony={"AirBNB"} requestStr={"United States"} priceStr={"$2.350"}></ListLineV>
      </div>
    );
  }
}

export default ListTabView;
import React from 'react';
import "./tabSubStyle.css"

const ChatLineV = ({headImg, userName, msgStr, timeStr}) => {
    return (
        <div className="chatLineV">
            <img className='chatImg' src={headImg}></img>
            <div className="chatContentV">
                <p className='chatNameL'>{userName}</p>
                <p className='chatMsgL'>{msgStr}</p>
            </div>
            <div className="chatTimeV">
                <p className='chatNameL'></p>
                <p className='chatMsgL'>{timeStr}</p>
            </div>
        </div>
    )
}

export default ChatLineV;
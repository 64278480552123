import React from "react"
import QustionTitle from "../component/QustionTitle"
import QuestionSelect from "../component/QuestionSelect"

import '../style/common.css'


class QuestionPage extends React.Component{
  questionWorkListArr =  [
      {questionId : 1, title : '1. What motivates you to perform well at your job??', 
         questionList : [
          {id : 1, title : 'A) Financial rewards', select : false},
          {id : 2, title : 'B) Recognition from peers', select : false},
          {id : 3, title : 'C) Personal growth', select : false},
          {id : 4, title : 'D) Team success', select : false}
         ],
       select : false
      },

      {questionId : 2, title : 'How do you prioritize your work tasks?', 
          questionList : [
           {id : 1, title : 'A) By deadlines', select : false},
           {id : 2, title : 'B) By importance', select : false},
           {id : 3, title : 'C) By difficulty', select : false},
           {id : 4, title : 'D) I don\'t prioritize, I do tasks as they come', select : false}
          ],
        select : false
       },

       {questionId : 3, title : 'What is your preferred method of communication in a team setting?', 
          questionList : [
           {id : 1, title : 'A) Email', select : false},
           {id : 2, title : 'B) Instant messaging', select : false},
           {id : 3, title : 'C) Face-to-face meetings', select : false},
           {id : 4, title : 'D) Phone calls', select : false}
          ],
        select : false
       },

       {questionId : 4, title : 'How do you handle stress in the workplace?', 
          questionList : [
           {id : 1, title : 'A) I thrive under pressure', select : false},
           {id : 2, title : 'B) I take breaks to clear my mind', select : false},
           {id : 3, title : 'C) I ask for help from colleagues', select : false},
           {id : 4, title : 'D) I become overwhelmed', select : false}
          ],
        select : false
       },

       {questionId : 5, title : 'Which of the following best describes your work style?', 
          questionList : [
           {id : 1, title : 'A) Collaborative', select : false},
           {id : 2, title : 'B) Independent', select : false},
           {id : 3, title : 'C) A mix of both', select : false},
           {id : 4, title : 'D) I adapt based on the situation', select : false}
          ],
        select : false
       },

       {questionId : 6, title : 'If you encounter a conflict with a coworker, what is your first step?', 
          questionList : [
           {id : 1, title : 'A) Discuss it directly with them', select : false},
           {id : 2, title : 'B) Ignore it and hope it resolves', select : false},
           {id : 3, title : 'C) Report it to a manager', select : false},
           {id : 4, title : 'D) Seek advice from other colleagues', select : false}
          ],
        select : false
       },



       {questionId : 7, title : 'How do you keep up with industry trends?', 
          questionList : [
           {id : 1, title : 'A) Reading articles and publications', select : false},
           {id : 2, title : 'B) Attending workshops and seminars', select : false},
           {id : 3, title : 'C) Networking with professionals', select : false},
           {id : 4, title : 'D) I don’t actively keep up', select : false}
          ],
        select : false
       },

       {questionId : 8, title : 'What do you consider the most important quality in a leader?', 
          questionList : [
           {id : 1, title : 'A) Communication skills', select : false},
           {id : 2, title : 'B) Decision-making ability', select : false},
           {id : 3, title : 'C) Empathy and support', select : false},
           {id : 4, title : 'D) Vision and strategy', select : false}
          ],
        select : false
       },

       {questionId : 9, title : 'How do you approach problem-solving?', 
          questionList : [
           {id : 1, title : 'A) Analyze all data before deciding', select : false},
           {id : 2, title : 'B) Trust my intuition', select : false},
           {id : 3, title : 'C) Collaborate with others for input', select : false},
           {id : 4, title : 'D) Quickly implement a solution', select : false}
          ],
        select : false
       },

       {questionId : 10, title : 'What is your experience with teamwork?', 
          questionList : [
           {id : 1, title : 'A) I prefer to work alone', select : false},
           {id : 2, title : 'B) I have extensive experience in teams', select : false},
           {id : 3, title : 'C) I have some experience but prefer solo work', select : false},
           {id : 4, title : 'D) I have limited experience in teams', select : false}
          ],
        select : false
       },

       {questionId : 11, title : 'How do you handle feedback from supervisors?', 
          questionList : [
           {id : 1, title : 'A) I take it constructively', select : false},
           {id : 2, title : 'B) I feel defensive at first', select : false},
           {id : 3, title : 'C) I ignore it if I disagree', select : false},
           {id : 4, title : 'D) I ask for clarification', select : false}
          ],
        select : false
       },

       {questionId : 12, title : 'What is your greatest professional achievement?', 
          questionList : [
           {id : 1, title : 'A) Meeting a critical deadline', select : false},
           {id : 2, title : 'B) Leading a successful project', select : false},
           {id : 3, title : 'C) Receiving an award or recognition', select : false},
           {id : 4, title : 'D) Improving a process or system', select : false}
          ],
        select : false
       },

       {questionId : 13, title : 'How do you define success in your career?', 
          questionList : [
           {id : 1, title : 'A) Achieving personal goals', select : false},
           {id : 2, title : 'B) Earning a high salary', select : false},
           {id : 3, title : 'C) Making a positive impact', select : false},
           {id : 4, title : 'D) Gaining promotions and titles', select : false}
          ],
        select : false
       },

       {questionId : 14, title : 'Which software tools are you most comfortable using?', 
          questionList : [
           {id : 1, title : 'A) Microsoft Office Suite', select : false},
           {id : 2, title : 'B) Google Workspace', select : false},
           {id : 3, title : 'C) Project management tools (e.g., Trello, Asana)', select : false},
           {id : 4, title : 'D) I am not proficient with many tools', select : false}
          ],
        select : false
       },

       {questionId : 15, title : 'What do you do when faced with a tight deadline?', 
          questionList : [
           {id : 1, title : 'A) Work extra hours to meet it', select : false},
           {id : 2, title : 'B) Delegate tasks to others', select : false},
           {id : 3, title : 'C) Prioritize tasks and focus on essentials', select : false},
           {id : 4, title : 'D) Communicate for an extension', select : false}
          ],
        select : false
       },

       {questionId : 16, title : 'How do you ensure quality in your work?', 
          questionList : [
           {id : 1, title : 'A) Double-checking my work', select : false},
           {id : 2, title : 'B) Seeking feedback from peers', select : false},
           {id : 3, title : 'C) Following established procedures', select : false},
           {id : 4, title : 'D) I rely on my experience', select : false}
          ],
        select : false
       },

       {questionId : 17, title : 'What type of work environment do you prefer?', 
          questionList : [
           {id : 1, title : 'A) Fast-paced and dynamic', select : false},
           {id : 2, title : 'B) Structured and predictable', select : false},
           {id : 3, title : 'C) Flexible and adaptable', select : false},
           {id : 4, title : 'D) Remote and independent', select : false}
          ],
        select : false
       },

       {questionId : 18, title : 'How do you approach learning new skills?', 
          questionList : [
           {id : 1, title : 'A) I take courses or workshops', select : false},
           {id : 2, title : 'B) I learn from colleagues', select : false},
           {id : 3, title : 'C) I prefer self-study through books or online resources', select : false},
           {id : 4, title : 'D) I find it challenging to learn new skills', select : false}
          ],
        select : false
       },

       {questionId : 19, title : 'What role do you usually take in a team project?', 
          questionList : [
           {id : 1, title : 'A) Leader', select : false},
           {id : 2, title : 'B) Contributor', select : false},
           {id : 3, title : 'C) Supporter', select : false},
           {id : 4, title : 'D) Coordinator', select : false}
          ],
        select : false
       },

       {questionId : 20, title : 'How do you respond to changes in the workplace?', 
          questionList : [
           {id : 1, title : 'A) I adapt quickly', select : false},
           {id : 2, title : 'B) I find it challenging', select : false},
           {id : 3, title : 'C) I need time to adjust', select : false},
           {id : 4, title : 'D) I resist change', select : false}
          ],
        select : false
       }
  ]

  questionSearchListArr = [
      {searchId : 1, title : 'What is the primary purpose of this job position?', 
          questionList : [
           {id : 1, title : 'A) To increase sales', select : false},
           {id : 2, title : 'B) To manage projects', select : false},
           {id : 3, title : 'C) To enhance customer service', select : false},
           {id : 4, title : 'D) To conduct research', select : false}
          ],
        select : 0
       },

       {searchId : 1, title : 'Which of the following skills is most critical for this role?', 
          questionList : [
           {id : 1, title : 'A) Communication', select : false},
           {id : 2, title : 'B) Technical proficiency', select : false},
           {id : 3, title : 'C) Leadership', select : false},
           {id : 4, title : 'D) Creative thinking', select : false}
          ],
        select : 0
       },

       {searchId : 3, title : 'What level of education is required for this position?', 
          questionList : [
           {id : 1, title : 'A) High school diploma', select : false},
           {id : 2, title : 'B) Associate degree', select : false},
           {id : 3, title : 'C) Bachelor’s degree', select : false},
           {id : 4, title : 'D) Master’s degree', select : false}
          ],
        select : 0
       },

       {searchId : 4, title : 'How many years of experience are preferred for this role?', 
          questionList : [
           {id : 1, title : 'A) 0-2 years', select : false},
           {id : 2, title : 'B) 3-5 years', select : false},
           {id : 3, title : 'C) 6-8 years', select : false},
           {id : 4, title : 'D) 9+ years', select : false}
          ],
        select : 0
       },

       {searchId : 5, title : 'Which of the following certifications would be beneficial for candidates?', 
          questionList : [
           {id : 1, title : 'A) Project Management Professional (PMP)', select : false},
           {id : 2, title : 'B) Certified Public Accountant (CPA)', select : false},
           {id : 3, title : 'C) Six Sigma', select : false},
           {id : 4, title : 'D) All of the above', select : false}
          ],
        select : 0
       },

       {searchId : 6, title : 'What type of work environment does this job entail?', 
          questionList : [
           {id : 1, title : 'A) Remote', select : false},
           {id : 2, title : 'B) In-office', select : false},
           {id : 3, title : 'C) Hybrid', select : false},
           {id : 4, title : 'D) Fieldwork', select : false}
          ],
        select : 0
       },
       {searchId : 7, title : 'What software or tools should candidates be proficient in?', 
          questionList : [
           {id : 1, title : 'A) Microsoft Office Suite', select : false},
           {id : 2, title : 'B) Adobe Creative Suite', select : false},
           {id : 3, title : 'C) CRM systems', select : false},
           {id : 4, title : 'D) All of the above', select : false}
          ],
        select : 0
       },

       {searchId : 8, title : 'Which of the following best describes the company culture?', 
          questionList : [
           {id : 1, title : 'A) Formal and structured', select : false},
           {id : 2, title : 'B) Casual and flexible', select : false},
           {id : 3, title : 'C) Competitive and fast-paced', select : false},
           {id : 4, title : 'D) Collaborative and innovative', select : false}
          ],
        select : 0
       },

       {searchId : 9, title : 'What are the key performance indicators (KPIs) for this role?', 
          questionList : [
           {id : 1, title : 'A) Sales targets', select : false},
           {id : 2, title : 'B) Project deadlines', select : false},
           {id : 3, title : 'C) Customer satisfaction scores', select : false},
           {id : 4, title : 'D) All of the above', select : false}
          ],
        select : 0
       },

       {searchId : 10, title : 'What is the expected work schedule for this position?', 
          questionList : [
           {id : 1, title : 'A) Standard 9-5', select : false},
           {id : 2, title : 'B) Flexible hours', select : false},
           {id : 3, title : 'C) Shift work', select : false},
           {id : 4, title : 'D) Part-time', select : false}
          ],
        select : 0
       },

       {searchId : 11, title : 'What is the primary mode of communication within the team?', 
          questionList : [
           {id : 1, title : 'A) Email', select : false},
           {id : 2, title : 'B) Instant messaging', select : false},
           {id : 3, title : 'C) Video calls', select : false},
           {id : 4, title : 'D) In-person meetings', select : false}
          ],
        select : 0
       },

       {searchId : 12, title : 'Which personal attribute is most valued in this role?', 
          questionList : [
           {id : 1, title : 'A) Adaptability', select : false},
           {id : 2, title : 'B) Attention to detail', select : false},
           {id : 3, title : 'C) Initiative', select : false},
           {id : 4, title : 'D) Teamwork', select : false}
          ],
        select : 0
       },

       {searchId : 13, title : 'What type of training will be provided to new hires?', 
          questionList : [
           {id : 1, title : 'A) On-the-job training', select : false},
           {id : 2, title : 'B) Formal training sessions', select : false},
           {id : 3, title : 'C) Online courses', select : false},
           {id : 4, title : 'D) No training provided', select : false}
          ],
        select : 0
       },

       {searchId : 14, title : 'What are the potential career advancement opportunities?', 
          questionList : [
           {id : 1, title : 'A) Managerial roles', select : false},
           {id : 2, title : 'B) Specialized positions', select : false},
           {id : 3, title : 'C) Cross-departmental roles', select : false},
           {id : 4, title : 'D) All of the above', select : false}
          ],
        select : 0
       },

       {searchId : 15, title : 'How does the company support professional development?', 
          questionList : [
           {id : 1, title : 'A) Workshops and seminars', select : false},
           {id : 2, title : 'B) Tuition reimbursement', select : false},
           {id : 3, title : 'C) Mentorship programs', select : false},
           {id : 4, title : 'D) All of the above', select : false}
          ],
        select : 0
       },

       {searchId : 16, title : 'What is the company’s approach to feedback and performance reviews?', 
          questionList : [
           {id : 1, title : 'A) Annual reviews only', select : false},
           {id : 2, title : 'B) Continuous feedback', select : false},
           {id : 3, title : 'C) Bi-annual reviews', select : false},
           {id : 4, title : 'D) No formal process', select : false}
          ],
        select : 0
       },

       {searchId : 17, title : 'How important is teamwork in this role?', 
          questionList : [
           {id : 1, title : 'A) Not important', select : false},
           {id : 2, title : 'B) Somewhat important', select : false},
           {id : 3, title : 'C) Very important', select : false},
           {id : 4, title : 'D) Critical', select : false}
          ],
        select : 0
       },

       {searchId : 18, title : 'What benefits are offered to employees?', 
          questionList : [
           {id : 1, title : 'A) Health insurance', select : false},
           {id : 2, title : 'B) Retirement plans', select : false},
           {id : 3, title : 'C) Paid time off', select : false},
           {id : 4, title : 'D) All of the above', select : false}
          ],
        select : 0
       },

       {searchId : 19, title : 'What is the expected level of autonomy for this position?', 
          questionList : [
           {id : 1, title : 'A) High autonomy', select : false},
           {id : 2, title : 'B) Moderate autonomy', select : false},
           {id : 3, title : 'C) Low autonomy', select : false},
           {id : 4, title : 'D) No autonomy', select : false}
          ],
        select : 0
       },

       {searchId : 20, title : 'Which of the following best describes the ideal candidate?', 
          questionList : [
           {id : 1, title : 'A) A recent graduate eager to learn', select : false},
           {id : 2, title : 'B) An experienced professional with specific skills', select : false},
           {id : 3, title : 'C) A versatile individual capable of handling various tasks', select : false},
           {id : 4, title : 'D) A leader with a proven track record', select : false}
          ],
        select : 0
       }
  ]

    constructor(props){
        super(props)

        let newArr = this.generateRandomQuestionList()
        let tempOneDic = newArr[0]
        let questionArr = tempOneDic["questionList"]

        this.state = {
          currentIndex: 0,
          selectNum : 0,
          currentTitle : tempOneDic["title"],
          currentQuestionA : questionArr[0]["title"],
          currentQuestionB : questionArr[1]["title"],
          currentQuestionC : questionArr[2]["title"],
          currentQuestionD : questionArr[3]["title"],
          questionList : newArr
        }
    }


    generateRandomQuestion(min , max){
        let randomNum = Math.floor(Math.random() * (max - min + 1)) + min  
        return randomNum
    }

    generateRandomQuestionList(){
        let questionListArr = []
        if(this.props.workType == 1){
            questionListArr = this.questionWorkListArr
        }else{
            questionListArr = this.questionSearchListArr
        }

        var randomList = new Array()
        let randomNum = this.generateRandomQuestion(3, 5)
        let randomIndexArr = []
        for(let i = 0; i < randomNum; i++){
            let randomIndex = Math.floor(Math.random() * questionListArr.length)
            if(randomIndexArr.includes(randomIndex)){
                continue
            }
            randomIndexArr.push(randomIndex)
            randomList.push(questionListArr[randomIndex])
        }
        return randomList
    }

    clickQuestBtn = (indexNum)=>{
        console.log("clickQuestBtn:" + indexNum)
        this.setState({selectNum : indexNum})
    }

    clickNextBtn = ()=>{
      if (this.state.currentIndex < this.state.questionList.length - 1){
        let tempIndex = this.state.currentIndex + 1
        let tempOneDic = this.state.questionList[tempIndex]
        let questionArr = tempOneDic["questionList"]
        this.setState({
          currentIndex : tempIndex,
          selectNum : 0,
          currentTitle : tempOneDic["title"],
          currentQuestionA : questionArr[0]["title"],
          currentQuestionB : questionArr[1]["title"],
          currentQuestionC : questionArr[2]["title"],
          currentQuestionD : questionArr[3]["title"]
        })
        return
      }

      this.props.onClick()
    }

    clickSkipBtn = ()=>{
      if (this.state.currentIndex < this.state.questionList.length - 1){
        let tempIndex = this.state.currentIndex + 1
        let tempOneDic = this.state.questionList[tempIndex]
        let questionArr = tempOneDic["questionList"]
        this.setState({
          currentIndex : tempIndex,
          selectNum : 0,
          currentTitle : tempOneDic["title"],
          currentQuestionA : questionArr[0]["title"],
          currentQuestionB : questionArr[1]["title"],
          currentQuestionC : questionArr[2]["title"],
          currentQuestionD : questionArr[3]["title"]
        })
        return
      }

      this.props.onClick()
    }


    render(){
        return(
            <div className="QuestionPageV">

                <div className="questionTitleV">
                    <p className="qustionTitle1">{'TechHub'}</p>
                    <p className="qustionTitle2">{'Questions'}</p>
                </div>
                
                <QustionTitle title={this.state.currentTitle}></QustionTitle>
                <div style={{height: '10px'}}></div>
                <QuestionSelect title={this.state.currentQuestionA} showStats={this.state.selectNum === 1} indexNum={1} onClick={()=> this.clickQuestBtn(1)}></QuestionSelect>
                <QuestionSelect title={this.state.currentQuestionB} showStats={this.state.selectNum === 2} indexNum={2}  onClick={()=> this.clickQuestBtn(2)}></QuestionSelect>
                <QuestionSelect title={this.state.currentQuestionC} showStats={this.state.selectNum === 3} indexNum={3} onClick={()=> this.clickQuestBtn(3)}></QuestionSelect>
                <QuestionSelect title={this.state.currentQuestionD} showStats={this.state.selectNum === 4} indexNum={4} onClick={()=> this.clickQuestBtn(4)}></QuestionSelect>

                <div className="QustionSubmitBgV">
                    <div className="QustionSkipBtn" onClick={this.clickSkipBtn}>
                        <p className="skipL">{"Skip>>"}</p>
                    </div>

                    <div style={{width : '10px'}}></div>
                    <div className="QustionNextBtn" onClick={this.clickNextBtn}>
                        <p className="nextL">{"Next Question"}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuestionPage
import React from "react";
import './componentTool.css'

const  QustionTitle = ({title}) =>{
    return (
        <div className="questTitleV">
            <p className="questTitle">{title}</p>
        </div>
    )
}

export default QustionTitle
import React from 'react';
import './tabStyle.css'
import MineLineV from './MineLineV';

import TabNavStatusLV from './TabNavStatusLV'

class MineTabView extends React.Component {
    constructor(props) {
        super(props)

        console.log("-----------------")
    }

    render() {
        return (
            <div>
                <TabNavStatusLV title="Profile" showStatus={true} setImg={require('../../assets/tabimg/mineTab/settingImg.png')}></TabNavStatusLV>
                
                <div className='mineHeadCV'>
                    <img className="mineUserHeadImg" src={require('../../assets/tabimg/mineTab/userHeadImg.png')}></img>
                    <div className='mineHeadCL'>
                        <p className='headNameL'>AirBNB</p>
                        <p className='userEmalAndAddL'>airbnb@yourdomain.com</p>
                        <p className='userEmalAndAddL'>United States</p>
                    </div>
                </div>

                <MineLineV></MineLineV>

            </div>
        );
    }
}

export default MineTabView;
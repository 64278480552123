import React from "react";
import './componentTool.css'


const QuestionSelect = ({title, showStats,indexNum, onClick}) => {
    const selectImg = require("../assets/tool/selected.png")
    const unselectImg = require("../assets/tool/unselect.png")

    return(
        <div className="questionCV" onClick={onClick}>
            <img src={showStats ?selectImg : unselectImg} className="selectImgV"></img>
            <p className="questionCL">{title}</p>
        </div>
    )
}

export default QuestionSelect